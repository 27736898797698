import React from "react";

const GoogleMap = ({ extraClassName }) => {
  return (
    <div className={`google-map__${extraClassName}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2509.9698480881284!2d-114.02259738424799!3d51.01670587955743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717a83a463088f%3A0x6883e4ed43fd3d7f!2sNewby%20Enterprises!5e0!3m2!1sen!2sca!4v1647445307811!5m2!1sen!2sca"        className={`map__${extraClassName}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
